<template>
  <div>
    <van-action-sheet v-model="show" :actions="actions" @select="onSelect" />
    <van-nav-bar v-if="!isWeiXin" title="欢迎注册Ainvestcn">
      <!-- menu btn start -->
      <van-button slot="left" size="small" @click="back()">
        <van-icon name="arrow-left" />
      </van-button>
      <!-- menu btn end -->
      <!-- right tool menu start -->
      <!-- <van-button slot="right" size="small">
        <van-icon name="wap-nav" />
      </van-button>-->
      <!-- right tool menu start -->
    </van-nav-bar>
    <van-progress
        v-if="curentStep==1"
        style="margin-top:10px;margin-bottom:20px"
        pivot-text
        color="#f2826a"
        :percentage="20"
    />
    <van-progress
        v-if="curentStep==2"
        style="margin-top:10px;margin-bottom:20px"
        pivot-text
        color="#f2826a"
        :percentage="45"
    />
    <van-progress
        v-if="curentStep==3"
        style="margin-top:10px;margin-bottom:20px"
        pivot-text
        color="#f2826a"
        :percentage="99"
    />

    <div class="form-area">
      <van-row v-if="curentStep===1" id="step1">
        <h2 style="margin-left:5px">手机号注册</h2>
        <p style="margin-left:5px;color:grey">
          注册即代表已阅读并同意
          <span style="color:red">服务协议</span>和
          <span style="color:red">隐私政策</span>
        </p>
        <van-row style="margin-top:50px">
          <van-col span="24">
            <van-field
                v-model="form.phonenumber"
                type="tel"
                label="手机号(+86)"
                clearable
                required
                maxlength="11"
                placeholder="请输入手机号"
            >
              <!-- <van-icon slot="left-icon" name="https://b.yzcdn.cn/vant/icon-demo-1126.png" /> -->
            </van-field>

            <van-field
                v-model="phonecode"
                type="tel"
                center
                clearable
                required
                label="短信验证码"
                placeholder="请输入短信验证码"
                maxlength="6"
            >
              <van-button
                  slot="button"
                  size="small"
                  color="#f2826a"
                  type="primary"
                  :disabled="!vfphone"
                  @click="getCode()"
              >{{ istimer?"重发验证码"+timerCount+"s":"发送验证码" }}</van-button>
            </van-field>
          </van-col>
        </van-row>

        <!-- <van-row type="flex" justify="center" style="margin-top:5px;margin-bottom:5px">
          <van-checkbox v-model="consent" checked-color="#f2826a" shape="square">我已阅读并同意</van-checkbox>
        </van-row>
        <van-row type="flex" justify="center" style="margin-bottom:5px">
          <a href="http://">《爱玩特用户协议》</a>
        </van-row>
        <van-row type="flex" justify="center" style="margin-bottom:5px">
          <a href>《隐私政策申明》</a>
        </van-row>-->
        <van-row type="flex" justify="center" style="margin-top:40px">
          <van-button color="#f2826a" :disabled="!canStartReg" @click="gotoStep2()">开始注册</van-button>
        </van-row>
      </van-row>
      <van-row v-if="curentStep===2" id="step2">
        正在开通
        <van-loading type="spinner" />
      </van-row>
      <van-row v-if="curentStep===3" id="step3">
        <h2>绑定券商</h2>
        <van-row>
          <van-col span="24">
            <van-field
                v-model="securitiesname"
                type="text"
                label="券商"
                clearable
                required
                readonly
                placeholder="请选择券商"
                @click="selectSecurities()"
            >
              <van-icon slot="right-icon" name="arrow-down" @click="selectSecurities()" />
            </van-field>

            <van-field
                v-if="securities!==2"
                v-model="form.securitiesUsername"
                type="text"
                label="账号"
                clearable
                required
                placeholder="请填写账号"
                maxlength="15"
            >
              <!-- <van-icon slot="left-icon" name="https://b.yzcdn.cn/vant/icon-demo-1126.png" /> -->
            </van-field>
            <van-field
                v-if="securities!==2"
                v-model="form.securitiesPassword"
                type="password"
                label="交易密码"
                clearable
                required
                placeholder="请输入券商登录密码"
            />
            <van-field
                v-if="needCpass"
                v-model="form.securitiesCpass"
                type="password"
                label="通信密码"
                clearable
                placeholder="请输入通信密码"
            />
            <van-field
                v-if="securities==7"
                v-model="form.securitiesCpass"
                type="password"
                label="通信密码"
                clearable
                placeholder="请输入通信密码"
            />
            <van-field
                v-if="securities==7"
                v-model="comment"
                type="textarea"
                label="备注"
                clearable
                required
                placeholder="请备注您希望对接的券商名称，营业部等信息。"
                maxlength="100"
            >

              <!-- <van-icon slot="left-icon" name="https://b.yzcdn.cn/vant/icon-demo-1126.png" /> -->
            </van-field>
          </van-col>
        </van-row>
        <van-row type="flex" justify="center" style="margin-top:5px;margin-bottom:5px">
          <van-checkbox v-model="consent2" checked-color="#f2826a" shape="square">我已阅读并同意</van-checkbox>
        </van-row>
        <van-row type="flex" justify="center" style="margin-bottom:5px;margin-top:1vh">
          <a href="http://">《委托交易协议》</a>
        </van-row>
        <van-row type="flex" style="margin-top:1vh" justify="center">
          <!-- {{ securities }} -->

          <van-button
              v-if="securities!==2"
              color="#f2826a"
              :disabled="!consent2"
              @click="applyyidao()"
          >同意并提交</van-button>
          <van-button
              v-if="securities!==2"
              color="#f2826a"
              style="margin-left:2vw"
              :disabled="!consent2"
              @click="abort()"
          >暂不绑定</van-button>
        </van-row>
      </van-row>
    </div>
  </div>
</template>
<script>
// const Base64 = require('js-base64').Base64

import Vue from 'vue'
// import
import * as sysTools from '../../utils/sysTools'
import { getSmsCode,
  register,
  applyYidao,
  ckSmsCode,
  login,
  logout,
  islogin,
  fetchSecurities, autoApplymn } from '@/api/theApi'
import {
  NavBar,
  Row,
  Col,
  Cell,
  CellGroup,
  Icon,
  Field,
  Progress,
  Checkbox,
  CheckboxGroup,
  Dialog,
  Toast,
  ActionSheet,
  Loading
} from 'vant'

Vue

    .use(Progress)
    .use(Icon)

    .use(CellGroup)
    .use(Checkbox)
    .use(CheckboxGroup)
    .use(Toast)

export default {
  name: 'Regpage',
  components: {
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Col.name]: Col,
    [Cell.name]: Cell,
    [Dialog.Component.name]: Dialog.Component,
    [Field.name]: Field,
    [ActionSheet.name]: ActionSheet,
    [Loading.name]: Loading
  },
  data() {
    return {
      form: {
        nativeCode: 'imAinvestcnabc',
        phonenumber: '',
        username: '',
        password: '',
        passwordConfirm: '',
        securities: '',
        securitiesUsername: '',
        securitiesPassword: '',
        email: '',
        securitiesCpass: ''
      },
      securities: '',
      needCpass: false,
      securitiesname: '',
      phonecode: '',
      consent: false,
      consent2: true,
      ckSmsCodeFromserver: false,
      curentStep: 2,
      steps: {
        step1: {},
        step2: {},
        step3: {}
      },
      istimer: false,
      timerCount: 54,
      show: false,
      actions: [],
      comment: ''
    }
  },

  computed: {
    theUA() {
      return sysTools.getUA()
    },
    isWeiXin() {
      if (this.theUA.indexOf('micromessenger') !== -1) {
        return true
      } else {
        return false
      }
    },
    vfphone() {
      const pattern = /^1[3456789]\d{9}$/
      // const isused =
      if (pattern.test(this.form.phonenumber)) {
        return true
      }
      return false
    },
    canStartReg() {
      if (this.vfSmsCode() && this.vfphonenumber()) {
        return true
      } else {
        return false
      }
    },
    canfinReg() {
      // return true
      if (
          this.form.password === this.form.passwordConfirm
      ) {
        return true
      } else {
        return false
      }
    }
  },
  created() {
    console.log(this.theislogin())
    this.init2()
  },
  methods: {
    async init2() {
      // 获取券商列表
      const securities = await fetchSecurities()
      const actions = await securities.data
      console.log(actions)
      this.actions = actions.filter(item => {
        return item.id !== 2
      }).filter(item => {
        return item.isSupportAI === 1
      })
      // step1, 从url 中取 手机号
      // step2，从url 中取 注册用的临时钥匙，由注册验证码接口返回
      Toast.loading()
      const phone = this.$route.params.phone
      let mk = this.$route.query.mk
      mk = sysTools.b64decode(mk)
      // console.log(phone, mk)

      // step3，访问快速注册接口，提交手机号和临时钥匙，以及接口key，response 里返回base64处理过的username和pwd
      const obj = {
        nativeCode: 'imAinvestcnabc',
        phonenumber: phone,
        username: phone,
        mk: mk
      }
      const regRslt = await register(obj)
      const thepwd = sysTools.b64decode(regRslt.data.pw)
      const isloginRes = await islogin()
      // const login_statusRslt = await islogin()

      const username = phone

      if (isloginRes.login_status && username) {
        Toast.loading('已经登陆')
        const b64u = sysTools.b64encode(username)
        const url = '/user/' + b64u + '/'
        setTimeout(() => {
          this.$router.push({
            path: url
          })
        }, 2000)
      } else {
        localStorage.clear()
      }
      // 未登录但是本地缓存用户名还在，清除localStorage
      if (!isloginRes.login_status && username) {
        localStorage.clear()
      }
      if (isloginRes.login_status && !username) {
        logout().then(() => {

        })
      }

      // step4, 解码username和pwd 调用登陆接口
      if (regRslt.data.pw) {
        Toast.loading('正在加载')
      }
      if (regRslt.data.phonenumber === phone) {
        Dialog.alert({
          title: '注册成功',
          message: '恭喜手机用户' + phone + '注册成功'
        })

        //  这里要修改 //后续数据缓存到 url中
        login(phone, thepwd).then(
            res => {
              console.log('autologin', res)
              if (res.login_status) {
                localStorage.setItem('username', phone)
                localStorage.setItem('phonenumber', phone)
                localStorage.setItem('login_status', res.login_status)
                localStorage.setItem('logintime', new Date())
                this.curentStep = 3
              } else {
                Toast.fail('登陆失败')
              }
            }
        ).catch(() => {
          Toast.fail('登陆失败')
        })
      }
    },
    async init() {
      const phone = this.$route.params.phone
      const login_statusRslt = await islogin()
      const login_status = login_statusRslt.login_status

      //  从验证码页面跳过来
      if (phone && !login_status) {
        this.curentStep = 2
        this.form.phonenumber = phone
        const pwd = sysTools.randomStr(10)
        this.form.password = pwd
        this.form.passwordConfirm = pwd
      }

      const securities = await fetchSecurities()
      let actions = await securities.data
      actions = actions.filter(item => {
        return item.isSupportAI === 1
      })
      console.log(actions)
      const isloginRes = await islogin()
      const username = localStorage.getItem('username')
      // 浏览器缓存 当前页面未选择券商时，会触发这里
      if (isloginRes.login_status && username) {
        Toast.loading('已经登陆')
        const b64u = sysTools.b64encode(username)
        const url = '/user/' + b64u + '/'
        setTimeout(() => {
          this.$router.push({
            path: url
          })
        }, 2000)
      } else {
        localStorage.clear()
      }
      // 未登录但是本地缓存用户名还在，清除localStorage
      if (!isloginRes.login_status && username) {
        localStorage.clear()
      }
      if (isloginRes.login_status && !username) {
        logout().then(() => {

        })
      }
    },

    theislogin() {
      islogin().then(
          res => {
            if (res.login_status) {
              return true
            } else {
              return false
            }
          }
      )
    },
    abort() {
      const phone = this.$route.params.phone
      const b64u = sysTools.b64encode(phone)
      const url = '/user/' + b64u + '/'
      Dialog.confirm({
        title: '友情提示',
        message: '您可以后续在用户中心绑定券商'
      })
          .then(() => {
            // on confirm
            this.$router.push({
              path: url
            })
          })
          .catch(() => {
            // on cancel
          })
    },

    /**
     * 校验 手机号
     */
    vfphonenumber() {
      //  if (this.form.phonenumber.length===11) {
      //   return true
      //  }
      const pattern = /^1[3456789]\d{9}$/
      // const isused =
      if (pattern.test(this.form.phonenumber)) {
        return true
      }

      return false
    },
    async ckSmsCodeFromServer() {
      const rslt = await ckSmsCode(this.form.phonenumber, this.phonecode)
      if (rslt.msg === 'success') {
        this.ckSmsCodeFromserver = true
      } else {
        this.ckSmsCodeFromserver = false
      }
    },
    vfSmsCode() {
      // 如果 验证码长度不足 6位 ，返回false
      if (this.phonecode.length !== 6) {
        return false
      }

      return true
    },

    /**
     * 获取手机验证码
     */
    async getCode() {
      const phone = await this.vfphonenumber()
      console.log(phone)
      if (!phone) {
        Toast.fail('请检查手机号是否正确')
      } else {
        const smscode = await getSmsCode(this.form.phonenumber)
        console.log(smscode)
        Toast.success('验证码已发送，有效期10分钟')
      }
      // if (!this.vfphonenumber()) {
      //   // 未填写 手机号
      // } else {
      // }
    },

    /**
     * 校验手机验证码
     */

    /**
     * 校验用户名
     */
    vfUsername() {
      const pattern = /^[a-zA-Z0-9_-]{4,16}$/
      if (pattern.test(this.form.username)) {
        return true
      }
      return false
    },
    /**
     * 进入第二步
     */
    gotoStep2() {
      // 提交只包含 手机号的表单,

      ckSmsCode(this.form.phonenumber, this.phonecode).then(res => {
        if (res.msg === 'success') {
          this.curentStep = 2
        } else {
          Toast.fail('验证码错误')
        }
      })
      // 进入完善信息的页面
      // this.curentStep = 2
    },

    /**
     * 完成注册
     */
    async finReg() {
      // 发送表单到  接口
      // this.form.ua = this.theUA
      const phone = this.$route.params.phone
      if (phone) {
        this.form.phonenumber = phone
      }
      const regRslt = await register(this.form)
      if (regRslt.data.phonenumber) {
        Toast.loading('正在加载')
      }
      if (regRslt.data.phonenumber === this.form.phonenumber) {
        Dialog.alert({
          title: '注册成功',
          message: '恭喜手机用户' + this.form.phonenumber + '注册成功'
        })

        //  这里要修改
        login(this.form.username, this.form.password).then(
            res => {
              console.log('autologin', res)
              if (res.login_status) {
                localStorage.setItem('username', this.form.username)
                localStorage.setItem('phonenumber', this.form.phonenumber)
                localStorage.setItem('login_status', res.login_status)
                localStorage.setItem('logintime', new Date())
                this.curentStep = 3
              } else {
                Toast.fail('登陆失败')
              }
            }
        ).catch(() => {
          Toast.fail('登陆失败')
        })
      }
      console.log(regRslt)
      // 进入下一步
    },
    async applyyidao() {
      const phone = this.$route.params.phone
      const data = {
        nativeCode: 'imAinvestcnabc',
        phonenumber: phone,
        username: phone,
        securities: this.form.securities,
        securitiesPassword: this.form.securitiesPassword,
        securitiesUsername: this.form.securitiesUsername,
        ua: this.theUA
      }
      if (this.form.securities === 7) {
        data['comment'] = this.comment
      }
      if (this.needCpass) {
        data['securitiesCpass'] = this.form.securitiesCpass
      }
      console.log(data)
      if (data.securitiesUsername.trim() === "" || data.securitiesPassword.trim() === "") {
        Toast.fail('表单不能为空');
        return
      }
      if (this.form.securities === 7 && (typeof(data.comment) === 'undefined' || data.comment.trim() === "")) {
        Toast.fail('表单不能为空');
        return
      }
      const applyRslt = await applyYidao(data)
      if (applyRslt.msg === 'success') {
        // Toast.success('成功提交申请')
        Dialog.alert({
          title: '绑定成功',
          message: '立刻选择感兴趣的策略'
        }).then(() => {
          // on confirm
          this.$router.push({
            path: '/strategylist'
          })
        })
      } else {
        Toast.fail('申请失败')
        // 提示申请失败
      }
      // Toast.loading({
      //   mask: true,
      //   message: '加载中...'
      // })
      // const applyRslt = await applyYidao(data)
      // console.log(applyRslt.code)
    },
    back() {
      this.$router.go(-1)
    },
    selectSecurities() {
      this.show = true
    },
    /**
     * 券商选择时间
     */
    onSelect(item, index) {
      console.log(item, index)
      this.form.securities = item.id
      this.securitiesname = item.name
      this.securities = item.id
      if (item.requireFileds === 1) {
        this.needCpass = true
      }
      if (item.requireFileds === 0) {
        this.needCpass = false
      }
      this.show = false
    },

    autoDemoAccount() {
      // 请求接口获得 虚拟券商账号
      // 用提交并绑定账号给虚拟券商
      Dialog.alert({
        title: '提示',
        message: '我们将为您开通模拟账号，确保您可以体验到完整的软件功能'
      }).then(() => {
        // const reg = new RegExp('(^| )' + 'sissionid' + '=([^;]*)(;|$)')
        // const arr = document.cookie.match(reg)
        // console.log(arr)

        // const sessionid = unescape(arr[2])
        autoApplymn().then(res => {
          if (res.msg === 'success') {
            // if (condition) {

            // }
            Dialog.alert({
              title: '提交成功',
              message: '请选择感兴趣的策略'
            }).then(() => {
              // on confirm
              this.$router.push({
                path: '/strategylist'
              })
            })
          } else {
            Toast.fail('申请失败,请稍后再试试。')
            // 提示申请失败
          }
        })

        // fetchdemoAccount().then(
        //   (res) => {
        //     console.log(res)
        //     const rqdata = {
        //       nativeCode: 'imAinvestcnabc',
        //       phonenumber: this.$route.params.phone,
        //       securities: '2',
        //       securitiesCpass: '',
        //       securitiesPassword: res.securities_password,
        //       securitiesUsername: res.securities_username,
        //       ua: this.theUA
        //     }

        //     applyYidao(rqdata).then((res2) => {
        //       if (res2.msg === 'success') {
        //         // Toast.success('成功提交申请')

        //         Dialog.alert({
        //           title: '提交成功',
        //           message: '立刻选择感兴趣的策略'
        //         }).then(() => {
        //           // on confirm

        //           this.$router.push({
        //             path: '/strategylist'
        //           })
        //         })
        //       } else {
        //         Toast.fail('申请失败')
        //         // 提示申请失败
        //       }
        //     })
        //     // console.log(applyRslt.code)
        //   }
        // )
      })
    }
  }
}
</script>
<style lang="less" scoped>
</style>
